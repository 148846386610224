import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import get from 'lodash/get'
import Helmet from 'react-helmet'

import Layout from '../../layouts/index'

const BlogIndex = ({ data }) => {
  const siteTitle = 'Blog des amoureux de la bière - MisterGoodBeer'
  const posts = get(data, 'allMarkdownRemark.edges')
  const defaultThumbnail = get(data, 'defaultThumbnail')

  return (
    <Layout>
      <section className="section">
        <Helmet title={siteTitle} />
        <div
          className="container"
          style={{
            maxWidth: '960px',
          }}
        >
          {
            posts.map(({ node }) => {
              const title = get(node, 'frontmatter.title')
                || node.fields.slug

              const { excerpt } = node
              const { frontmatter: { date: publishedAt } } = node
              const { fields: { slug } } = node
              const thumbnail = get(node, 'frontmatter.ogimage.childImageSharp.sizes')
                || defaultThumbnail.childImageSharp.sizes
              const thumbnailDescription = node.frontmatter.ogimagedesc
                || node.fields.slug
              return (
                <article className="media" key={title}>
                  <figure className="media-left">
                    <div className="image is-128x128 is-hidden-mobile">
                      <Img
                        sizes={thumbnail}
                        alt={thumbnailDescription}
                      />
                    </div>
                  </figure>
                  <div className="media-content">
                    <div className="content">
                      <h2 className="title">
                        <Link to={slug}>
                          {title}
                        </Link>
                      </h2>
                      <h5 className="subtitle">
                        {publishedAt}
                      </h5>
                      <p>
                        {excerpt}
                      </p>
                    </div>
                    <nav className="level is-mobile">
                      <div className="level-left">
                        <a className="level-item" href="https://www.facebook.com">
                          Comment
                        </a>
                        <a className="level-item" href="https://www.twitter.com">
                          Retweet
                        </a>
                        <a className="level-item" href="https://www.facebook.com">
                          Like
                        </a>
                      </div>
                    </nav>
                  </div>
                </article>
              )
            })
          }
        </div>
      </section>
    </Layout>
  )
}

export default BlogIndex

// NOTE file relativePath is relative to gatsby-config of gatsby-source-filesystem
export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    defaultThumbnail: file(relativePath: { eq: "blog/blog-default-thumbnail.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 128, maxHeight: 128) {
          ...GatsbyImageSharpSizes
        }
      }
    }
    allMarkdownRemark(
      sort: {
        fields: [frontmatter___date],
        order: DESC
      }
    ) {
      edges {
        node {
          excerpt(pruneLength: 280)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM YYYY", locale: "fr")
            title
            ogimage {
              childImageSharp {
                sizes(maxWidth: 128, maxHeight: 128) {
                  ...GatsbyImageSharpSizes
                }
              }
              relativePath
            }
            ogimagedesc
          }
        }
      }
    }
  }
`
